:root {
  font-size: 62.5%;
  --oncourse-blue: #0364D8;
  --support-yellow: #FFE36C;
  --background-gray: #F8F8F8;
  --green: #A6CB95;
  --support-purple: #0D1680;
}

h1, h2, h3 {
  font-family: "neusa-next-std-compact", sans-serif;
  font-weight: 700;
  margin: 0;
}

p {
  margin: 0;
}

body {
  font-family: 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



